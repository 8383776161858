import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SearchField from '../search-field';
import {cloneDeep} from 'lodash';
import {createListData, search, addToStorage} from '../../services/utils';
import Tree from '../tree';
import * as TreeConstructor from '../../services/tree-builder';
import PageTitle from '../page-title';

export default class ClassesPage extends PureComponent {

    static propTypes = {
        data: PropTypes.array.isRequired
    }

    state = {
        fiter: '',
        parentStatus: createListData(TreeConstructor.buildClasses(cloneDeep(this.props.data)).length)
    }

    componentWillUnmount() {
        addToStorage('path', window.location.pathname);
    }

    onParentStatusHandler = (id) => {
        this.setState(({parentStatus}) => {
            return {
                parentStatus: {...parentStatus, [id]: !parentStatus[id]}
            }
        })
    }

    searchHandler = (filter) => {
        this.setState({ filter })
    }

    render() {
        const {props: {data}, state: {parentStatus, filter}, onParentStatusHandler, searchHandler} = this;
        const tree = search(TreeConstructor.buildClasses(cloneDeep(data)), filter);
        const elements = TreeConstructor.render(cloneDeep(tree), '/v1/Context', parentStatus, onParentStatusHandler);

        return (
            <>
                <PageTitle text="Class higherarchy"/>
                <SearchField searchHandler={searchHandler}/>
                <Tree>{elements}</Tree>
            </>
        )
    }
}