import React, { PureComponent } from 'react';
import RouterTabs from '../tabs';
import { MainTab, GeneralInformation } from '../tabs-components';
import { extractURL, parser, isEmptyProp } from '../../services/utils';
import { ClassesPage } from '../pages';
import { withRouter } from 'react-router-dom';
import ErrorIndicator from '../error-indicator';
import GithubApi from '../../services/api';
import Spinner from '../spinner';
import {getFromStorage} from '../../services/utils';
import PropTypes from 'prop-types';
import atob from 'atob';
import mdFileCD from '../../documentation/ClassdefinitionOverview.md';
import mdFileCV from '../../documentation/context.md';
import './details.scss';

const githubApi = new GithubApi();
const tabView = [
    'General information',
    'Context',
    'Vocabulary',
    'Class definition',
    'Data example'
]

const createURL = (str, list, extractor) => {
    let arrayURL = str.split('/');

    const item = arrayURL[arrayURL.length - 1];
    const mainURL = extractor(list, item);
    const contextURL = arrayURL.join('/');

    return {
        mainURL,
        contextURL,
        item
    }
}

class Details extends PureComponent {
    static defaultProps = {
        url: '',
        item: '',
        defUrl: ''
    }

    static propTypes = {
        url: PropTypes.string,
        item: PropTypes.string,
        defUrl: PropTypes.string
    }

    state = {
        contextData: {
            content: null,
            link: null,
            size: null
        },
        classDefinitionData: {
            content: null,
            link: null,
            size: null,
            url: null
        },
        vocabularyData: {
            content: null,
            link: null,
            size: null,
            url: null
        },
        classDefinitionUrl: null,
        vocabularyUrl: null,
        hasError: false
    }

    componentDidMount() {
        this.fetchData();
        const prevLink = getFromStorage('path').split('/') || null;
        this.prevURL = prevLink[prevLink.length - 1];
        console.log(this.prevURL);

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params[0] !== prevProps.match.params[0]) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const {classes} = this.props
        const { params } = this.props.match;
        const {mainURL, contextURL} = createURL(params[0], classes, extractURL);

        if (!params[0]) {
            return ;
        }

        Promise.all([
            githubApi.getData(`v1/Vocabulary/${mainURL}`),
            githubApi.getData(`v1/Context/${contextURL}`),
            githubApi.getData(`v1/ClassDefinitions/${mainURL}`)
        ]).then(data => {
            data.map((dt) => {
                const {path} = dt;
                path.includes('Vocabulary') ? 
                    this.setState({
                        vocabularyData: {
                            content: parser(atob, dt.content),
                            link: dt.download_url,
                            size: (dt.size / 1024).toFixed(2)
                        }
                    }) :
                path.includes('Context') ? 
                    this.setState({
                        contextData: {
                            content: parser(atob, dt.content),
                            link: dt.download_url,
                            size: (dt.size / 1024).toFixed(2)
                        },
                        classDefinitionUrl: parser(atob, dt.content)['@context']['@classDefinition'],
                        vocabularyUrl: parser(atob, dt.content)['@context']['@vocab']
                    }) :
                this.setState({
                    classDefinitionData: {
                        content: parser(atob, dt.content),
                        link: dt.download_url,
                        size: (dt.size / 1024).toFixed(2)
                    }
                })
            })
            this.setState({
                hasError: false
            })
        }, _ => {
            this.setState({
                hasError: true
            })
        })
    }

    render() {
        const { contextData, classDefinitionData, vocabularyData, hasError, classDefinitionUrl, vocabularyUrl  } = this.state;
        const { classes } = this.props;
        const { params } = this.props.match;
        const {contextURL, item} = createURL(params[0], classes, extractURL);

        if (hasError) {
            return (
                <DetailsView 
                    renderLeftData={() => <ClassesPage data={classes} />}
                    renderRightData={() => <ErrorIndicator />}
                />
            )
        }

        if (!params[0]) {
            return (
                <DetailsView 
                    renderLeftData={() => <ClassesPage data={classes} />}
                    renderRightData={() => `Choose any class`}/>
            )
        }

        if (!isEmptyProp(contextData) || 
            !isEmptyProp(classDefinitionData) || 
            !isEmptyProp(vocabularyData) || 
            !vocabularyData.content[item] ||
            !vocabularyUrl ||
            !classDefinitionUrl) {
            return (
                <DetailsView 
                    renderLeftData={() => <ClassesPage data={classes} />}
                    renderRightData={() => <Spinner />}
                />
            )
        }

        return (
            <DetailsView 
                renderLeftData={() => <ClassesPage data={classes} />}
                renderRightData={() => (
                    <RouterTabs tabView={tabView}>
                        <GeneralInformation classes={classes} item={item} data={vocabularyData.content} fullUrl={contextURL}/>
                        <MainTab data={contextData} mdFileSrc={mdFileCV}/>
                        <MainTab data={vocabularyData} url={vocabularyUrl} mdFileSrc={mdFileCV}/>
                        <MainTab data={classDefinitionData} url={classDefinitionUrl} mdFileSrc={mdFileCD}/>
                        <h2>Data example</h2>
                    </RouterTabs>
                )}
            />
        )
    }
}

const DetailsView = (props) => {
    const prevURL = null;

    return (
        <div className="row">
            {
                prevURL === 'classes-higherarchy' ? (
                    <>
                        <div className="col-sm-3">
                            {
                                props.renderLeftData()
                            }
                        </div>
                        <div className="col-sm-9">
                            {
                                props.renderRightData()
                            }
                        </div>
                    </>
                ) :
                prevURL === 'classes-grid' ? (
                    <div className="col-sm-12">
                        {
                            props.renderRightData()
                        }
                    </div>
                ) : (
                    <>
                        <div className="col-sm-3">
                            {
                                props.renderLeftData()
                            }
                        </div>
                        <div className="col-sm-9">
                            {
                                props.renderRightData()
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default withRouter(Details);