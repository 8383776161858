function extractData(data, type) {
    return data.filter(item => item['@type'] === type)
}

function createListData(num) {
    let obj = {}

    for (let i = 1; i <= num; i++) {
        obj[i] = true;
    }

    return obj;
}

function search(items, filter) {
    if (!filter || filter.length === 0) {
        return items;
    }

    filter = filter.toLowerCase();

    let result = [];

    for (let nodeIdx = 0; nodeIdx <= items.length - 1; nodeIdx++) {
        let currentNode = items[nodeIdx];
        let currentId = currentNode['@id'].toLowerCase();
        let currentChildren = currentNode['children'];

        if (!currentChildren && !currentId.includes(filter)) {
            continue;
        } else if (currentId.includes(filter)) {
            if (currentChildren) {
                currentNode['children'] = search(currentChildren, filter);
            }

            result.push(currentNode);
            continue;
        } else if (!currentId.includes(filter)) {
            if (currentChildren) {
                currentNode['skip'] = true;
                currentNode['children'] = search(currentChildren, filter);
            }

            result.push(currentNode);
            continue;
        } else {
            result = [];
        }
    }
    return result;
    
}

function extractURL(data, str) {
    if(!str) {
        return ;
    }

    let url = str;

    let item = data.filter(el => {
        return el['@id'].split(':')[1] === str
    })[0];
    
    let match = item['subClassOf'].split(':')[0];
    
    if (match !== 'dli' && match !== 'rdfs') {
        
        url = `${extractURL(data, item['subClassOf'].split(':')[1])}/${url}`;
    } 

    return url;
}

function createParentChain(data, str) {
    let result = [];

    let el = data.filter(child => {
        return child['@id'].includes(str)
    })[0]['subClassOf'];

    if (el.includes('dli') || el.includes('rdfs')) {
        result = [...result, el];
        return result;
    } else {
        result = [...result, el, ...createParentChain(data, el)];
    }

    return result;
}

function createQueryArray(str) {
    let data = str.split('/');

    return data.map((item, i, arr) => arr.slice(0, i + 1).join('/'));
}

function addToStorage(key, value) {
    return localStorage.setItem(key, JSON.stringify(value))
}

function getFromStorage(key) {
    return JSON.parse(localStorage.getItem(key));
}

function parser(parserFn, data) {
    return JSON.parse(parserFn(data));
}

function isEmptyProp(obj) {
    return Object.keys(obj)
            .map(prop => {
                return obj[prop] !== null
            })
            .every(el => el === true);
}

export {
    extractData, createListData, 
    search, extractURL, 
    createQueryArray, createParentChain,
    addToStorage, getFromStorage,
    parser, isEmptyProp
}