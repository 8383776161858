import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {getFromStorage, addToStorage} from '../../services/utils';
import Collapse from '@material-ui/core/Collapse';
import Markdown from 'react-markdown'
import Spinner from '../spinner';

export default class ClassDefinition extends PureComponent {

    static defaultProps = {
        url: `https://standards.oftrust.net${window.location.pathname}`
    }

    static propTypes = {
        data: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired
    }

    state = {
        showDetails: true,
        markdownText: getFromStorage(this.props.mdFileSrc) || null
    }

    componentDidMount() {
        const {markdownText} = this.state;

        if (!markdownText) {
            this.fetchMDFile()
        }
    }

    fetchMDFile = () => {
        const {mdFileSrc} = this.props;
        fetch(mdFileSrc)
            .then(response => {
                return response.text()
            })
            .then(markdownText => {
                this.setState({
                    markdownText
                }, _ => {
                    addToStorage(mdFileSrc, markdownText)
                })
            })
    }

    showDetailsHandler = () => {
        this.setState(({showDetails}) => {
            return {
                showDetails: !showDetails
            }
        })
    }

    render() {
        const {showDetails, markdownText} = this.state
        const {data: {content, size, link}, url} = this.props;

        const iconStyle = {
            'transform': `rotate(${showDetails ? 0 : 180}deg)`
        }

        if (!markdownText) {
            return <Spinner />
        }

        return (
            <div className="class-definition-details">
                <div className="item-description">
                    <h4 className="item-description__title">URI</h4>
                    <a className="item-description__body item-description__body--link" href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </div>
                <div className="class-definition-header">
                    <h3>Implementation</h3>
                    <span className="chevron-down" style={iconStyle} onClick={this.showDetailsHandler}>
                        <i className="fas fa-chevron-down"></i>
                    </span>
                </div>
                <div className="class-definition-body">
                    <div className="class-definition-top">
                        <div className="left">
                            <span className="number-of-lines">84 lines (384 sloc)</span> |
                            {" "}
                            <span className="file-size">{`${size} KB`}</span>
                        </div>
                        <div className="right">
                            <a className="download-btn" href={link} target="_blank" rel="noopener noreferrer"> Download </a>
                        </div>
                    </div>
                    <Collapse in={showDetails} timeout={350}>
                        <pre >{JSON.stringify(content, undefined, 2)}</pre>
                    </Collapse>
                </div>
                <div className="markdown">
                    <Markdown source={markdownText}/>
                </div>
            </div>
        )
    }
}