import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../navigation';
import Collapse from '@material-ui/core/Collapse';
import Logo from '../logo';

import './header.scss';

export default class Header extends Component {

    state = {
        showMobilMenu: false
    }

    showMenuHandler = () => {
        this.setState(state => {
            return {
                showMobilMenu: !state.showMobilMenu
            }
        })
    }

    render() {

        const {showMobilMenu} = this.state;

        return (
            <>
            <div className="platform-header">
                <div className="row d-flex align-items-center">
                    <div className="col-sm-3 col-md-3 col-8">
                        <Logo />
                    </div>
                    <div className="col-sm-5 offset-sm-4 col-md-5 offset-md-4 col-4 text-right">
                        <Navigation>
                            <NavLink to="/v1/classes-higherarchy" activeClassName="active">Classes higherarchy</NavLink>
                            <NavLink to="/v1/classes-grid" activeClassName="active">Classes grid</NavLink>
                            <NavLink to="/v1/properties" activeClassName="active">Properties</NavLink>
                        </Navigation>
                    </div>
                </div>
                
            </div>
            {
                showMobilMenu ? <h1>Hello</h1> : null
            }
            </>
        )
    }
}