import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';

const LinkRouter = props => <Link {...props} component={RouterLink}/>

function capitalizeFirstLetter(str) {
    let result = str
        .split(' ')
        .filter(word => typeof word[0] === 'string')
        .map(word => {
            word = word.charAt(0).toUpperCase() + word.substr(1);
            return word;
        })
        .join(' ');

    return result;
}

const RouterBreadcrumbs = ({items}) => {

    let newItems = items.filter(el => el.toLowerCase() !== 'v1');

    return (
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small"/>}>
            {
                !items.length ? (
                    <Typography color="textPrimary">
                        Home
                    </Typography>
                ) : (
                    <LinkRouter to="/">
                        Home
                    </LinkRouter>
                )
            }
            {
            newItems.map((value, index) => {
                const last = index === newItems.length - 1;

                let to = `/v1/${newItems.slice(0, index + 1).filter(el => el.toLowerCase() !== 'v1').join('/')}`;
                const label = capitalizeFirstLetter(newItems[index]);

                if (value.toLowerCase() === 'context') {
                    to += '/';
                    return null;
                }

                if (value.toLowerCase() === 'identity' || value.toLowerCase() === 'link') {
                    return null
                }
                
                return last ? (
                    <Typography color="textPrimary" key={to}>
                        {label}
                    </Typography>
                ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                        {label}
                    </LinkRouter>
                )
            })
            }
        </Breadcrumbs>
    )
}

RouterBreadcrumbs.propTypes = {
    items: PropTypes.array
}

export default RouterBreadcrumbs;