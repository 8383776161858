import React from 'react';
import './tree.scss';

const Tree = ({children}) => {
    return (
        <ul className="tree">
            {children}
        </ul>
    )
}

export default Tree;