import React, { PureComponent } from 'react';
import {extractURL, addToStorage, getFromStorage} from '../../services/utils';
import PageTitle from '../page-title';
import {Link} from 'react-router-dom';

const fields = [
    '@id',
    '@type',
    'subClassOf',
    'Label',
    'Comment',
    'Same as',
    'Equivalent class'
];

const strToName = (str) => {
    return str.split(' ').map((word, idx) => {
        return !idx ? word[0].toLowerCase() + word.slice(1) : word[0].toUpperCase() + word.slice(1)
    }).join('');
}

export default class ClassesGrid extends PureComponent {
    state = {
        '@id': '',
        '@type': '',
        'subClassOf': '',
        'label': '',
        'comment': '',
        'sameAs': '',
        'equivalentClass': '',
        filteredField: ''
    }

    componentWillUnmount() {
        addToStorage('path', window.location.pathname);
    }

    renderTable(filteredArray, fullData) {
        return filteredArray.map(el => {
            let url = extractURL(fullData, el['@id'].split(':')[1]);
            url = el['subClassOf'] === 'dli:Role' ? `Context/Link/${url}` : `Context/Identity/${url}`;

            const label = 'dli:label' in el ? el['dli:label'][0]['rdfs:label']['@value'] : null;
            const comment = 'dli:comment' in el ? el['dli:comment'][0]['rdfs:comment']['@value'] : null;

            return (
                <tr key={el['@id']}>
                    <td>
                        <Link to={url}>{el['@id']}</Link>
                    </td>
                    <td>{el['@type']}</td>
                    <td>{el['subClassOf']}</td>
                    <td>{label}</td>
                    <td>{comment}</td>
                    <td></td>
                    <td></td>
                </tr>
            )
        })
    }

    onSearchChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }

    onSubmit = (e, name) => {
        e.preventDefault();

        this.setState({
            filteredField: name
        })
    }

    search(name, classes) {
        const filter = name ? this.state[name].toLowerCase() : '';

        switch (name) {
            case 'label':
                return classes.filter(el => 'dli:label' in el ? el['dli:label'][0]['rdfs:label']['@value'].toLowerCase().includes(filter) : false);
            case 'comment':
                return classes.filter(el => 'dli:comment' in el ? el['dli:comment'][0]['rdfs:comment']['@value'].toLowerCase().includes(filter) : false);
            case 'subClassOf':
                return classes.filter(el => el['subClassOf'].toLowerCase().includes(filter));
            case '@id':
                return classes.filter(el => el['@id'].toLowerCase().includes(filter));
            case '@type':
                return classes.filter(el => el['@type'].toLowerCase().includes(filter));
            default:
                return classes
        }
    }

    render() {
        const { classes } = this.props;
        const { filteredField } = this.state;

        const data = this.search(filteredField, classes);

        return (
            <>
                <PageTitle text="Pot viewer - Level 1"/>
                <table className="pot-viewer-table">
                    <thead>
                        <tr>
                            {
                                fields.map(el => {
                                    return (
                                        <th key={el}>
                                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.99998 0.713989L5.99998 3.98672H-1.52588e-05L2.99998 0.713989ZM2.99998 9.71399L-1.52588e-05 6.44126H5.99998L2.99998 9.71399Z" fill="#4C4C51" />
                                            </svg>

                                            {el}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="search-row">
                            {
                                fields.map(el => {
                                    const name = strToName(el)
                                    return (
                                        <td key={el}>
                                            <form onSubmit={(e) => this.onSubmit(e, name)}>
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    name={name}
                                                    value={this.state[name]}
                                                    onChange={(event) => this.onSearchChange(event)} />
                                                <button type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </form>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        {
                            this.renderTable(data, classes)
                        }
                    </tbody>
                </table>
            </>
        )
    }
}