import React, {Component} from 'react';
import ErrorBoundary from '../error-boundary';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import ScrollToTop from '../scroll-to-top';
import PageNavigation from '../page-navigation';
import GithubApi from '../../services/api';
import Details from '../details';
import {extractData} from '../../services/utils';
import {ClassesPage, ClassesGrid} from '../pages';
import atob from 'atob';
import ErrorIndicator from '../error-indicator';
import Spinner from '../spinner';

import './app.scss';

const githubApi = new GithubApi();

export default class App extends Component {

    state = {
        data: null,
        loading: true,
        hasError: false
    }

    componentDidMount() {
        githubApi
            .getData('v1/Ontology/pot')
            .then(data => {
                this.setState({ 
                    data: JSON.parse(atob(data.content)),
                    loading: false
                })
            })
            .catch(err => {
                console.error(err);
                this.setState({ 
                    loading: false,
                    hasError: true
                })
            })
    }


    render() {
        const {data, hasError} = this.state;

        if (!data && !hasError) {
            return <Spinner />
        }

        if (hasError) {
            return <ErrorIndicator />
        }

        const classes = extractData(data.defines, 'pot:Class');
        // const properties = extractData(data.defines, 'rdf:Property');

        return (
            <ErrorBoundary>
                <Router>
                    <ScrollToTop>
                        <Header />
                        <PageNavigation />
                        <div className="platform-content-wrapper">
                            <Switch>
                                <Route path="/v1/" exact render={() => <h1>Home</h1>}/>
                                <Route path="/v1/classes-higherarchy" exact render={() => 
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ClassesPage data={classes}/>
                                        </div>
                                    </div>
                                } />
                                <Route path="/v1/classes-grid" render={() => <ClassesGrid classes={classes}/>}/>
                                <Route path="/v1/Context/*" exact render={() => <Details classes={classes}/>}/>
                                <Route path="/v1/properties"  render={() => <h1>Properties</h1>}/>
                                <Route path="/v1/statistics"  render={() => <h1>Statistics</h1>}/>
                                <Redirect to="/v1/"/>
                            </Switch>
                        </div>
                        <Footer />
                    </ScrollToTop>
                </Router>
            </ErrorBoundary>
        )
    }
}