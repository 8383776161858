import React from 'react';
import logo from '../../images/logo.png';
import './logo.scss';

const Logo = () => {
    return (
        <div className="logo-block">
            <img src={logo} alt="Platform Of Trust"/>
            <div className="logo-text">
                <span className="logo-text-big">Platform</span>
                <span className="logo-text-small">of trust</span>
            </div>
        </div>
    )
}

export default Logo;