import React from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import {extractURL} from '../../services/utils';
import { Link } from 'react-router-dom';
import { createParentChain } from '../../services/utils';

function renderItemProperties(data, item) {
    const dataCopy = cloneDeep(data);

    delete dataCopy['@context'];
    delete dataCopy[item]

    return dataCopy;
}

function renderTable(obj) {
    return Object.keys(obj).length ? (
        Object.keys(obj).filter(name => '@id' in obj[name]).sort().map(name => {
            let properties = obj[name];
            const comment = 'rdfs:comment' in properties ? properties['rdfs:comment']['en-us'] : 'Has no description';
            const range = 'range' in properties ? properties['range'][0] : '';
            return (
                <tr key={name}>
                    <td>
                        <span className="class-name-decor">{`:${name}`}</span>
                    </td>
                    <td></td>
                    <td>
                        {comment}
                    </td>
                    <td>
                        {range}
                    </td>
                </tr>
            )
        })
    ) : (
            <tr>
                <td colSpan="4" style={{ 'textAlign': 'center', 'fontSize': '20px' }}> Has no properties</td>
            </tr>
        )
}

const GeneralInformation = (props) => {
    
    const { item, classes, data} = props;
    const itemData = data[item];
    const label = 'rdfs:label' in itemData ? itemData['rdfs:label']['en-us'] : 'Has no label';
    const comment = 'rdfs:comment' in itemData ? itemData['rdfs:comment']['en-us'] : 'Has no description';
    const superClasses = createParentChain(classes, item);
    const itemProperties = renderItemProperties(data, item);

    return (
        <>
            <h3 className="item-header">:{item}</h3>
            <div className="item-description-wrapper">
                <div className="item-description">
                    <h4 className="item-description__title">Context URI</h4>
                    <a className="item-description__body item-description__body--link"
                        href={`https://standards.oftrust.net${window.location.pathname}`}
                        target="_blank"
                        rel="noopener noreferrer">{`https://standards.oftrust.net${window.location.pathname}`}</a>
                </div>
                <div className="item-description">
                    <h4 className="item-description__title">Label</h4>
                    <p className="item-description__body item-description__body--text">
                        {label}
                    </p>
                </div>
                <div className="item-description">
                    <h4 className="item-description__title">Description</h4>
                    <p className="item-description__body item-description__body--text">
                        {comment}
                    </p>
                </div>
                <div className="item-description">
                    <h4 className="item-description__title">Superclasses({superClasses.length})</h4>
                    <ul className="item-description__body item-description__body--list">
                        {
                            superClasses.map(el => {
                                let parentURL = '/v1/Context';
                                parentURL = el === 'dli:Role' ? `${parentURL}/Link/` : `${parentURL}/Identity/`;
                                const title = el.split(':')[1];
                                const label = (el.includes('dli') || el.includes('rdfs')) ?
                                    <span className="superclass-disabled">{el}</span> :
                                    <Link to={`${parentURL}${extractURL(classes, title)}`}>{`:${el.split(':')[1]}`}</Link>
                                return (
                                    <li key={el} className="superclass-decor">{label}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="item-usage-wrapper">
                <h4>Usage</h4>
                <p>Instances of :{item} can have the following properties:</p>
                <table className="item-usage-table">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable(itemProperties)}
                    </tbody>
                </table>
            </div>
        </>
    )
}

GeneralInformation.propTypes = {
    item: PropTypes.string.isRequired,
    classes: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    fullUrl: PropTypes.string.isRequired
}

export default React.memo(GeneralInformation);